.game-desk ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
  
.game-desk li {
    padding: 20px;
    border: 1px solid silver;
    display: flex;
    position: relative;
    cursor: pointer;
}
.game-desk.foundall li {
    cursor: auto;
}

/* .poznate-opery {
    background-image: url("./assets/poznate_opery.jpg");
    width: 650px;
    height: 107px;
    cursor: pointer;
} */

.bravo-dialog {
    margin: 20px;
}

.bravo-back img {
    height: 60px;
    width: auto;
}