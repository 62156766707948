ul {
  list-style: none;
  padding-inline-start: 0;
}

.centre {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.game-page {
  max-width: 1010px;
  text-align: center;
  margin: auto;
}

.game-page .game-back img {
  height: 60px;
  margin-top: -10px;
  margin-bottom: 20px;
  cursor: pointer;
}

/* .game-desk {
} */

/* .game-desk .images-row {
  display: flex;
}
.game-desk .title-row {
  text-align: center;
} */

.card-cell {
  width: 128px;
  height: 184px;

  margin:10px;

  cursor: pointer;

  display: inline-block;
}


.card-cell-0 {
  background-image: url("./assets/pexeso00.jpg");
}

.menu-card-char {
  width: 180px;
  height: 250px;
}

.char-01 {
  background-image: url("./assets/char_01.jpg");
}
.char-02 {
  background-image: url("./assets/char_02.jpg");
}
.char-03 {
  background-image: url("./assets/char_03.jpg");
}
.char-04 {
  background-image: url("./assets/char_04.jpg");
}
.char-05 {
  background-image: url("./assets/char_05.jpg");
}
.char-06 {
  background-image: url("./assets/char_06.jpg");
}
.char-07 {
  background-image: url("./assets/char_07.jpg");
}
.char-08 {
  background-image: url("./assets/char_08.jpg");
}
.char-09 {
  background-image: url("./assets/char_09.jpg");
}

.char-01s {
  background-image: url("./assets/char_01s.jpg");
}
.char-02s {
  background-image: url("./assets/char_02s.jpg");
}
.char-03s {
  background-image: url("./assets/char_03s.jpg");
}
.char-04s {
  background-image: url("./assets/char_04s.jpg");
}
.char-05s {
  background-image: url("./assets/char_05s.jpg");
}
.char-06s {
  background-image: url("./assets/char_06s.jpg");
}
.char-07s {
  background-image: url("./assets/char_07s.jpg");
}
.char-08s {
  background-image: url("./assets/char_08s.jpg");
}
.char-09s {
  background-image: url("./assets/char_09s.jpg");
}

.menu-card-text {
  width: 281px;
  height: 84px;
}

.text-00 {
  background-image: url("./assets/text_00.jpg");
}
.text-01 {
  background-image: url("./assets/text_01.png");
}
.text-02 {
  background-image: url("./assets/text_02.png");
}
.text-03 {
  background-image: url("./assets/text_03.png");
}
.text-04 {
  background-image: url("./assets/text_04.png");
}
.text-05 {
  background-image: url("./assets/text_05.png");
}
.text-06 {
  background-image: url("./assets/text_06.png");
}
.text-07 {
  background-image: url("./assets/text_07.png");
}
.text-08 {
  background-image: url("./assets/text_08.png");
}
.text-09 {
  background-image: url("./assets/text_09.png");
}

.text-01s {
  background-image: url("./assets/text_01s.png");
}
.text-02s {
  background-image: url("./assets/text_02s.png");
}
.text-03s {
  background-image: url("./assets/text_03s.png");
}
.text-04s {
  background-image: url("./assets/text_04s.png");
}
.text-05s {
  background-image: url("./assets/text_05s.png");
}
.text-06s {
  background-image: url("./assets/text_06s.png");
}
.text-07s {
  background-image: url("./assets/text_07s.png");
}
.text-08s {
  background-image: url("./assets/text_08s.png");
}
.text-09s {
  background-image: url("./assets/text_09s.png");
}


.menu-card-text2 {
  width: 84px;
  height: 281px;
}

.text2-00 {
  background-image: url("./assets/text2_00.jpg");
}
.text2-01 {
  background-image: url("./assets/text2_01.png");
}
.text2-02 {
  background-image: url("./assets/text2_02.png");
}
.text2-03 {
  background-image: url("./assets/text2_03.png");
}
.text2-04 {
  background-image: url("./assets/text2_04.png");
}
.text2-05 {
  background-image: url("./assets/text2_05.png");
}
.text2-06 {
  background-image: url("./assets/text2_06.png");
}
.text2-07 {
  background-image: url("./assets/text2_07.png");
}
.text2-08 {
  background-image: url("./assets/text2_08.png");
}
.text2-09 {
  background-image: url("./assets/text2_09.png");
}



.menu-card-deska {
  width: 90px;
  height: 90px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-size: contain;
}

.deska-00 {
  background-image: url("./assets/deska_00.jpg");
}
.deska-01 {
  background-image: url("./assets/deska_01.jpg");
}
.deska-02 {
  background-image: url("./assets/deska_02.jpg");
}
.deska-03 {
  background-image: url("./assets/deska_03.jpg");
}
.deska-04 {
  background-image: url("./assets/deska_04.jpg");
}
.deska-05 {
  background-image: url("./assets/deska_05.jpg");
}
.deska-06 {
  background-image: url("./assets/deska_06.jpg");
}
.deska-07 {
  background-image: url("./assets/deska_07.jpg");
}
.deska-08 {
  background-image: url("./assets/deska_08.jpg");
}
.deska-09 {
  background-image: url("./assets/deska_09.jpg");
}

.deska-01s {
  background-image: url("./assets/deska_01s.jpg");
}
.deska-02s {
  background-image: url("./assets/deska_02s.jpg");
}
.deska-03s {
  background-image: url("./assets/deska_03s.jpg");
}
.deska-04s {
  background-image: url("./assets/deska_04s.jpg");
}
.deska-05s {
  background-image: url("./assets/deska_05s.jpg");
}
.deska-06s {
  background-image: url("./assets/deska_06s.jpg");
}
.deska-07s {
  background-image: url("./assets/deska_07s.jpg");
}
.deska-08s {
  background-image: url("./assets/deska_08s.jpg");
}
.deska-09s {
  background-image: url("./assets/deska_09s.jpg");
}


.can-next {
  border: solid 3px tomato;
  position: fixed;
  bottom: 20px;
  left: calc(50% - 50px);
}

.can-next img {
  margin-bottom: -4px;
  cursor: pointer;
}
