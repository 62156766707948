.footer {
    max-width: 918px;
    margin-bottom: 20px;
}

.footer .wrapper {
    display: flex;
}

.logos .imgs {
    margin: 0 -10px 0 10px;
    text-align: left;
}

.logos img {
    height: 35px;
    margin: 0 10px;
} 

.logos .leos-web {
    text-align: center;
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 10px;
}

.logos .leos-web a {
    color: black;
    text-decoration: none;
}
