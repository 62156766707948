.char-page {
    display: flex;
    /* flex-wrap: wrap; */
}

/* .poznate-postavu {
    background-image: url("./assets/p_postavu.jpg");
    width: 503px;
    height: 107px;
} */

.poznate-postavu-dialog_cz {
    background-image: url("./assets/cz/p_postavu_pokr_main.png");
    width: 896px;
    height: 318px;
}
.poznate-postavu-dialog_en {
    background-image: url("./assets/eng/p_postavu_pokr_main.png");
    width: 896px;
    height: 318px;
}


.characters {
    width: 65%;
}

.characters ul {
    display: flex;
    flex-wrap: wrap;
}

.characters li .menu-card-char {
    margin: 6px;
    border: 4px solid white;
    border-radius: 10px;
}
  
.characters li .menu-card-char.activated {
    border: 4px solid greenyellow;
}

.characters li .menu-card-char:hover {
    border: 4px solid silver;
}

.characters li .menu-card-char.activated:hover {
    border: 4px solid greenyellow;
}
  
.texts {
    width: 35%;
}

.texts ul {
    display: flex;
    flex-wrap: wrap;
}

.texts li .menu-card-text {
    border: 4px solid transparent;
    border-radius: 10px;
}
.texts li .menu-card-text.activated {
    border: 4px solid greenyellow;
}

.texts li .menu-card-text:hover {
    border: 4px solid silver;
}
.texts li .menu-card-text.activated:hover {
    border: 4px solid greenyellow;
}
