.opera-page {
    display: flex;
    /* flex-wrap: wrap; */
}

/* .poznate-operu {
    background-image: url("./assets/spoj_desku.jpg");
    width: 650px;
    height: 107px;
} */
.poznate-operu-dialog_cz {
    background-image: url("./assets/cz/spoj_desku_main.png");
    width: 896px;
    height: 318px;
}
.poznate-operu-dialog_en {
    background-image: url("./assets/eng/spoj_desku_main.png");
    width: 896px;
    height: 269px;
}

.opera-page .operas {
    width: 815px;
}

.opera-page .menu-card {
    margin-top: calc(1em + 270px);
    margin-right: 20px;
}
.opera-page .menu-card .menu-card-text {
    width: 180px;
    height: 54px;
    background-size: contain;
}

.opera-page .menu-card-char {
    border: 4px solid white;
}
.opera-page .menu-card-char:hover {
    border: 4px solid silver;
}
.opera-page .menu-card-char.activated {
    border: 4px solid greenyellow;
}
.opera-page .menu-card-char.activated:hover {
    border: 4px solid greenyellow;
}

.opera-page .operas ul {
    display: flex;
    flex-wrap: wrap;
}

.opera-card-deska {
    width: 249px;
    height: 250px;
    background-size: contain;
    margin: 0 10px 10px 0;
    border: 4px solid white;
}

.opera-card-deska:hover {
    border: 4px solid silver;
}

.opera-card-deska.activated {
    border: 4px solid greenyellow;
}
.opera-card-deska.activated:hover {
    border: 4px solid greenyellow;
}

.winner-info {
    padding: 30px;
}

.winner-info .col {
    display: inline-block;
    margin: 0 15px;
}

.winner-info .name-col {
    display: inline-block;
    text-align: center;
    margin-top: 15px;
}

.winner-info .menu-card-char {
    width: 252px;
    height: 350px;
    background-size: contain;
}

.winner-info .opera-deska {
    width: 350px;
    height: 350px;
    background-size: contain;
}
