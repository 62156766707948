.header2 {
    text-align: center;
}

.menu {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 60px;
}

.menu ul li {
    display: inline-block;
    cursor: pointer;
}

.menu li.active {
    border-bottom: solid 4px tomato;
}

.menu li:hover {
    background-color: tomato;
}

.menu .select-diff {
    margin-top: 90px;
    margin-bottom: -30px;
}