.info-dialog {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:rgba(255,255,255,0.65);
    cursor: pointer;
}

.info-dialog-content {
    border: solid thin tomato;
    background-color: white;

    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);    
}
